import React, { Fragment, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Intro from '../../components/Intro';
import SEO from '../../components/SEO';

import './index.scss';

export default function BlogPost({ data }) {
  const post = data.markdownRemark;

  const title = post.frontmatter.title;

  const onClick = useCallback(e => {
    e.preventDefault();

    let label = `Unknown from ${title}`;

    if (e.target.nodeName.toLowerCase() === 'img' && e.target.alt) {
      label = e.target.alt;
    } else if (e.target.nodeName.toLowerCase() === 'a') {
      label = e.target.innerText;
    }

    const a = e.target.closest('a');

    trackCustomEvent({
      category: 'Blog post',
      action: 'click',
      label,
    });

    window.open(a.getAttribute('href'), '_blank');
  }, [title]);

  useEffect(() => {
    const links = document.querySelectorAll('#markdown a');

    links.forEach(link => {
      link.addEventListener('click', onClick);
    });

    return () => {
      links.forEach(link => {
        link.removeEventListener('click', onClick);
      });
    };
  }, [onClick]);

  return (
    <Fragment>
      <SEO
        title={title}
        description={post.excerpt}
        // image={post.frontmatter.image.publicURL}
        pathname={post.fields.slug}
        article
      />
      <article itemProp="blogPost" itemScope itemType="http://schema.org/BlogPosting">
        <Intro
          className="Intro--blog-post"
          title={title}
          subtitle={post.frontmatter.date}
          article
          showButton={false}
        />
        <div className="BlogPost section-padding">
          <div className="grid-small">
            <div id="markdown" itemProp="articleBody" dangerouslySetInnerHTML={{ __html: post.html }} />
          </div>
        </div>
      </article>
    </Fragment>
  );
}

BlogPost.propTypes = {
  data: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      excerpt(pruneLength: 160)
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM YYYY")
      }
      html
    }
  }
`;
